import React, { useState } from 'react';
import { getUserSessionToken } from './getUserSessionToken';
import { Hub } from "aws-amplify";

const Feedback = (signOut) => {
  async function feedbackform() {
    console.log("Running feedback")
    const { accessToken, idToken } = await getUserSessionToken();
    sessionStorage.setItem("globalToken", idToken);
    const feedbackformName =document.getElementById('Contact-Name').value;
    const feedbackformEmail = document.getElementById('Contact-Email').value;
    const usefulSelect = document.getElementById('Contact-Usefulness');
    const selectedUsefulness = usefulSelect.options[usefulSelect.selectedIndex].value;
    const recommendSelect = document.getElementById('Contact-Recommend');
    const selectedRecommend = recommendSelect.options[recommendSelect.selectedIndex].value;
    const feedbackformMessage = document.getElementById('Contact-Message').value;
  
    const feedbackendpoint = 'https://azg1xs3ixl.execute-api.us-east-1.amazonaws.com/dev/feedback/';
  
    // Remember those form values we just grabbed? We're going to put them into an object here.
    const data = {
        name: feedbackformName,
        email: feedbackformEmail,
        usefulness: selectedUsefulness,
        recommendation: selectedRecommend,
        message: feedbackformMessage
        }
        const response = fetch(
          `https://azg1xs3ixl.execute-api.us-east-1.amazonaws.com/dev/feedback/`,
          {
            method: 'POST',
            mode: 'cors',
            headers: {
              'Content-Type': 'application/json',
              Accept: '*/*',
              Authorization: idToken,
            },
            body: JSON.stringify(data),
          }
        );
        const message = response
        document.getElementById('wf-form-Contact-Form-Feedback').reset(); 
        
  }
  return (
    <><div data-collapse="medium" data-animation="default" data-duration="400" data-no-scroll="1" data-easing="ease" data-easing2="ease" role="banner" className="left-navigation w-nav">
      <a href="/" className="logo-link w-nav-brand">
        <h1 className="brand-text">REALTOR COPILOT</h1>
      </a>
      <nav role="navigation" className="nav-menu w-nav-menu">
        <a href="/" className="nav-link w-nav-link">Home</a>
        <a href="/feedback" aria-current="page" className="nav-link w-nav-link w--current">PROVIDE FEEDBACK</a>
        <a onClick={signOut} className="button-2 logout w-button">Log Out</a>
      </nav>
      <div className="hamburger-button w-nav-button">
        <div className="w-icon-nav-menu"></div>
      </div>
      <div className="social-footer w-hidden-medium w-hidden-small w-hidden-tiny">
        <a href="https://functionfactories.com" target="_blank" className="social-icon-link w-inline-block"><img src="images/FunctionFactories-FullWhiteLogo.png" width="250" sizes="(max-width: 991px) 100vw, 250px" srcSet="images/FunctionFactories-FullWhiteLogo-p-500.png 500w, images/FunctionFactories-FullWhiteLogo-p-800.png 800w, images/FunctionFactories-FullWhiteLogo.png 1501w" alt=""/></a>
      </div>
    </div>
    <section className="rl_section_contact6">
        <div className="rl-padding-global">
          <div className="rl-container-large">
            <div className="rl-padding-section-large">
              <div className="w-layout-grid rl_content6_component">
                <div className="rl_contact6_content">
                  <h2 className="rl-heading-style-h2">We&#x27;d like your feedback!</h2>
                  <div className="rl_contact6_spacing-block-2"></div>
                  <p className="rl-text-style-medium">Realtor Friend is a community tool for all. We want to make it as useful as possible.</p>
                  <div className="rl_contact6_spacing-block-3"></div>
                  <div className="rl_contact6_contact-list">
                    <div id="w-node-_9d9e4189-d8f0-476d-53f9-01e38d06605e-cd555b82" className="rl_contact6_item">
                      <div className="rl_contact6_icon-wrapper">
                        <div className="rl_contact6_icon w-embed"><svg width=" 100%" height=" 100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M18 2H6C3.79086 2 2 3.79086 2 6V21.5C2 21.7761 2.22386 22 2.5 22C2.63212 21.9992 2.75833 21.9451 2.85 21.85L5.85 18.85C6.42433 18.2908 7.19856 17.9847 8 18H18C20.2091 18 22 16.2091 22 14V6C22 3.79086 20.2091 2 18 2ZM14 12.5C14 12.7761 13.7761 13 13.5 13H6.5C6.22386 13 6 12.7761 6 12.5V11.5C6 11.2239 6.22386 11 6.5 11H13.5C13.7761 11 14 11.2239 14 11.5V12.5ZM17.5 9C17.7761 9 18 8.77614 18 8.5V7.5C18 7.22386 17.7761 7 17.5 7H6.5C6.22386 7 6 7.22386 6 7.5V8.5C6 8.77614 6.22386 9 6.5 9H17.5Z" fill="currentColor"></path>
                        </svg></div>
                      </div>
                      <div className="rl-text-style-regular">info@functionfactories.com</div>
                    </div>
                  </div>
                </div>
                <div className="rl_contact6_form-block w-form">
                  <form id="wf-form-Contact-Form-Feedback" name="wf-form-Contact-Form-Feedback" data-name="Contact-Form-Feedback" method="get" className="rl_contact6_form">
                    <div className="rl_contact6_form-field-2col">
                      <div className="rl_contact6_form-field-wrapper">
                        <label htmlFor="Contact-Name" className="rl-field-label">Name</label>
                        <input type="text" className="rl-form-input w-input" maxLength="256" name="Contact-Name" data-name="Contact-Name" placeholder="" id="Contact-Name" required=""/>
                        </div>
                    </div>
                    <div className="rl_contact6_form-field-2col">
                      <div id="email" className="rl_contact6_form-field-wrapper">
                        <label htmlFor="Contact-Email" className="rl-field-label">Email</label>
                        <input type="email" className="rl-form-input w-input" name="Contact-Email" data-name="Contact-Email" placeholder="" id="Contact-Email" required />
                        </div>
                    </div>
                    <div className="rl_contact6_form-field-wrapper">
                      <label htmlFor="Contact-Usefulness" className="rl-field-label">How useful has Realtor Friend been for your needs?</label>
                      <select id="Contact-Usefulness" name="Contact-Recommend" data-name="Contact-Recommend" required="" className="rl-form-select-input w-select">
                      <option value="">Select one...</option>
                      <option value="Extremely Useful">Extremely Useful</option>
                      <option value="Useful">Useful</option>
                      <option value="Indifferent">Indifferent</option>
                      <option value="Not Useful">Not Useful</option>
                      <option value="Will never Use It">Will never Use It</option>
                    </select></div>
                    <div className="rl_contact6_form-field-wrapper">
                      <label htmlFor="Contact-Recommend" className="rl-field-label">Are you likely to recommend Realtor Friend to others?</label>
                      <select id="Contact-Recommend" name="Contact-Recommend-2" data-name="Contact Recommend 2" required="" className="rl-form-select-input w-select">
                      <option value="">Select one...</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select></div>
                    <div className="rl_contact6_form-field-wrapper">
                      <label htmlFor="Contact-Message" className="rl-field-label">Please tell us how we can improve!</label>
                      <textarea id="Contact-Message" name="Contact-Message" maxLength="5000" data-name="Contact-Message" placeholder="Type your message..." required="" className="rl-form-text-area w-input"></textarea>
                    </div>
                    <div id="w-node-_9d9e4189-d8f0-476d-53f9-01e38d0660ae-cd555b82" className="rl_contact6_button-wrapper">
                      <div className="rl_contact6_spacing-block-7">
                        </div>
                        
                    </div>
                  </form>
                  <button onClick={feedbackform} id="Submit-feedback" className="submit-button w-button">Submit</button>
                  <div className="rl-success-message-2 w-form-done">
                    <div className="rl-success-text">Thank you! Your submission has been received!</div>
                  </div>
                  <div className="rl-error-message-2 w-form-fail">
                    <div className="rl-error-text">Oops! Something went wrong while submitting the form.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section></>
  );
}
export default Feedback;
