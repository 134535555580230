import './App.css';
import './css/webflow.css';
import './css/normalize.css';
import './css/realtor-friend.webflow.css';
import './webflow-override.css';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { Authenticator } from '@aws-amplify/ui-react';
import handleSignOut from './signOut';
import { getUserSessionToken } from './getUserSessionToken';
import React, { useState, useEffect, useRef, useNavigate } from 'react';
import { Hub } from "aws-amplify";


function Home() {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [authStatus, setAuthStatus] = useState("");
  const listener = (data) => {
    setAuthStatus(data.payload.event);
  };
  console.log(authStatus);
  Hub.listen("auth", listener);

  async function GetResults(){
    const { accessToken, idToken } = await getUserSessionToken();
    sessionStorage.setItem("globalToken", idToken); 
    setLoading(true);
    const selectedBed = document.getElementById("field-bed").value;
    //Get the selected number of bathrooms
    const selectedBath = document.getElementById("Field-Bath").value;
    //Get the home type
    const selectedHome = document.getElementById("Field-Home-Type").value;
    //Get the garage info
    const selectedGarage = document.getElementById("Field-Garage").value;
    //Get the possible check boxes
    const isMultiStory = document.getElementById("checkbox-multi-story").checked;
    const hasWasherDryer = document.getElementById("checkbox-washer-dryer-hookup").checked;
    const hasFireplace = document.getElementById("checkbox-fireplace").checked;
    const hasBasement = document.getElementById("checkbox-basement").checked;
    // Get the "Square Feet" textarea value
    const squareFeetValue = document.getElementById("field-square-feet").value;
    // Get the City/Town textarea value
    const CityTownValue = document.getElementById("field-city-town").value;
    const data = {
      bedrooms: selectedBed,
      bathrooms: selectedBath,
      homeType: selectedHome,
      garage: selectedGarage,
      multiStory: isMultiStory,
      washerDryer: hasWasherDryer,
      fireplace: hasFireplace,
      basement: hasBasement,
      sqFeet: squareFeetValue,
      cityTown: CityTownValue
    };
    console.log(data)
    const response = await fetch(
      `https://azg1xs3ixl.execute-api.us-east-1.amazonaws.com/dev/description/`,
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Accept: '*/*',
          Authorization: idToken,
        },
        body: JSON.stringify(data),
      }
    );
    const message = await response.json();
    setResults(message);
    console.log(message)
    setLoading(false);
  };

function toggle_mobile_nav() {
  var x = document.getElementById("mobile-nav-menu");
  if (x.style.display === "block") {
      x.style.display = "none";
  } else {
      x.style.display = "block";
  }
};

  return (

      <><div
          data-collapse="medium"
          data-animation="default"
          data-duration="400"
          data-no-scroll="1"
          data-easing="ease"
          data-easing2="ease"
          role="banner"
          className="left-navigation w-nav"
        >
          <a
            href="/"
            aria-current="page"
            className="logo-link w-nav-brand w--current"
          >
            <h1 className="brand-text">REALTOR COPILOT</h1>
          </a>
          <nav id="mobile-nav-menu" role="navigation" className="nav-menu w-nav-menu">
            <div className="nav-wrapper-mobile">
              <a href="/" className="nav-link w-nav-link">
                Home
              </a>
              <a href="/feedback" className="nav-link w-nav-link">
                PROVIDE FEEDBACK
              </a>
              <a id="Logout" onClick={handleSignOut} className="button-2 logout w-button">
                Log Out
              </a>
            </div>
          </nav>
          <div onClick={toggle_mobile_nav} className="hamburger-button w-nav-button">
            <div className="w-icon-nav-menu"></div>
          </div>
          <div className="social-footer w-hidden-medium w-hidden-small w-hidden-tiny">
            <a
              href="https://functionfactories.com"
              target="_blank"
              className="social-icon-link w-inline-block"
            >
              <img
                src={require("./images/FunctionFactories-FullWhiteLogo.png")}
                width="250"
                sizes="(max-width: 991px) 100vw, 250px"
                alt="" />
            </a>
          </div>
        </div><div className="content">
            <div id="home" className="banner">
              <div className="hero-title-wrapper">
                <h1 className="hero-heading">Welcome to REALTOR COPILOT</h1>
                <h5 className="heading">Select your parameters</h5>
              </div>
              <div className="container-2 craft-section w-container">
                <div className="div-block">
                  <div className="w-form">
                    <form
                      id="wf-form-Craft-Form"
                      name="wf-form-Craft-Form"
                      data-name="Craft Form"
                      className="form"
                    >
                      <label htmlFor="field-bed" className="field-label">
                        Bedrooms
                      </label>
                      <select
                        id="field-bed"
                        name="Field-Bed"
                        data-name="Field Bed"
                        required=""
                        className="select-field w-node-_71029a5e-d6ed-8943-f2e4-471eb63aa54a-00dd523e w-select"
                      >
                        <option defaultValue="">Select one...</option>
                        <option defaultValue="1">1</option>
                        <option defaultValue="2">2</option>
                        <option defaultValue="3">3</option>
                        <option defaultValue="4">4</option>
                        <option defaultValue="5">5</option>
                        <option defaultValue="6">6</option>
                        <option defaultValue="7">7</option>
                        <option defaultValue="8">8</option>
                        <option defaultValue="9">9</option>
                      </select>
                      <label htmlFor="Field-Bath" className="field-label-2">
                        Bathrooms
                      </label>
                      <select
                        id="Field-Bath"
                        name="Field-Bath"
                        data-name="Field Bath"
                        required=""
                        className="select-field-2 w-select"
                      >
                        <option defaultValue="">Select one...</option>
                        <option defaultValue="1">1</option>
                        <option defaultValue="1.5">1.5</option>
                        <option defaultValue="2">2</option>
                        <option defaultValue="2.5">2.5</option>
                        <option defaultValue="3.5">3</option>
                        <option defaultValue="3.5">3.5</option>
                        <option defaultValue="4">4</option>
                        <option defaultValue="4.5">4.5</option>
                        <option defaultValue="5">5</option>
                        <option defaultValue="5.5">5.5</option>
                        <option defaultValue="6">6</option>
                        <option defaultValue="6.5">6.5</option>
                        <option defaultValue="7">7</option>
                        <option defaultValue="7.5">7.5</option>
                        <option defaultValue="8">8</option>
                        <option defaultValue="8.5">8.5</option>
                        <option defaultValue="9">9</option>
                        <option defaultValue="9.5">9.5</option>
                      </select>
                      <label htmlFor="Field-Home-Type" className="field-label-3">
                        Home Type
                      </label>
                      <select
                        id="Field-Home-Type"
                        name="Field-Home-Type"
                        data-name="Field Home Type"
                        required=""
                        className="select-field-3 w-select"
                      >
                        <option defaultValue="">Select one...</option>
                        <option defaultValue="House">House</option>
                        <option defaultValue="Condo">Condo</option>
                        <option defaultValue="Townhouse">Townhouse</option>
                      </select>
                      <label htmlFor="Field-Garage" className="field-label-4">
                        Garage Spots
                      </label>
                      <select
                        id="Field-Garage"
                        name="Field-Garage"
                        data-name="Field Garage"
                        required=""
                        className="select-field-4 w-select"
                      >
                        <option defaultValue="">Select one...</option>
                        <option defaultValue="None">None</option>
                        <option defaultValue="1">1</option>
                        <option defaultValue="2">2</option>
                        <option defaultValue="3">3</option>
                      </select>
                      <label
                        id="w-node-_38b9dcb0-633a-0285-3d36-fcf14a2557d8-00dd523e"
                        className="w-checkbox"
                      >
                        <input
                          type="checkbox"
                          id="checkbox-multi-story"
                          name="checkbox-multi-story"
                          data-name="checkbox-multi-story"
                          className="w-checkbox-input" />
                        Multi-Story
                      </label>
                      <label
                        id="w-node-_5401ca62-7b1a-221f-59cc-84124e0678af-00dd523e"
                        className="w-checkbox"
                      >
                        <input
                          type="checkbox"
                          id="checkbox-washer-dryer-hookup"
                          name="checkbox-washer-dryer-hookup"
                          data-name="checkbox-washer-dryer-hookup"
                          className="w-checkbox-input" />
                        Washer/Dryer Hook Up
                      </label>
                      <label
                        id="w-node-_5e5d5d32-b833-ae69-5e02-6df14a93abdd-00dd523e"
                        className="w-checkbox"
                      >
                        <input
                          type="checkbox"
                          id="checkbox-fireplace"
                          name="checkbox-fireplace"
                          data-name="checkbox-fireplace"
                          className="w-checkbox-input" />
                        Fireplace
                      </label>
                      <label
                        id="w-node-_04cc0189-5f54-6f5d-b4d8-d2a845ffb06a-00dd523e"
                        className="w-checkbox"
                      >
                        <input
                          type="checkbox"
                          id="checkbox-basement"
                          name="checkbox-basement"
                          data-name="checkbox-basement"
                          className="w-checkbox-input" />
                        Basement
                      </label>
                      <label htmlFor="field-square-feet" className="field-label-4">
                        Square Feet
                      </label>
                      <textarea
                        id="field-square-feet"
                        name="Field-Square-Feet"
                        maxLength="5000"
                        data-name="Field Square Feet"
                        placeholder="e.g. 1,500"
                        className="textarea-2 w-node-_74ecbb84-b79a-3dd4-4a21-76b4718bbd2b-00dd523e w-input"
                      ></textarea>
                      <label htmlFor="field-city-town" className="field-label-4">
                        City/Town
                      </label>
                      <textarea
                        id="field-city-town"
                        name="Field-City-Town"
                        maxLength="5000"
                        data-name="Field City/Town"
                        placeholder="e.g. Chicago"
                        required=""
                        className="textarea w-node-_01d23095-3331-cfca-7235-1d66c2d83d12-00dd523e w-input"
                      ></textarea>
                    </form>
                    <div className="rf-button-wrapper">
                      <button
                        data-wait="Please wait..."
                        onClick={GetResults}
                        id="Craft"
                        className="submit-button w-node-_35805ad9-f1e3-3819-02b8-fb17f133e2c6-00dd523e w-button"
                      >
                        Craft
                      </button>
                    </div>
                    <div className="w-form-done">
                      <div>Give me a few moments!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>
                        Oops! Something went wrong while submitting the form.
                      </div>
                    </div>
                  </div>
                </div>
                {loading && (
                  <div className="loader loader1">
                    <div>
                      <div>
                        <div>
                          <div>
                            <div>
                              <div></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div id="Results-Block" className="results-block">
                  {results}
                </div>
              </div>
            </div>
            <footer className="footer center wf-section">
              <div className="w-container">
                <div className="footer-text">
                  Copyright © 2023 Function Factories.
                </div>
              </div>
            </footer>
            <script src="js/webflow.js" type="text/javascript"></script>
          </div>
    </>
  );
}

export default Home;
