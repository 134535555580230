import { useEffect } from "react";
import { useState } from "react"; 
import { Auth } from "aws-amplify";
import { Navigate } from 'react-router-dom';

import { Authenticator, useAuthenticator, View } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import { useNavigate, useLocation } from 'react-router';

export function Login() {
  const { route } = useAuthenticator((context) => [context.route]);
  const location = useLocation();
  const navigate = useNavigate();
  let from = location.state?.from?.pathname || '/';
  console.log("from:", from);
  useEffect(() => {
    const handleRedirect = async () => {
      if (route === 'authenticated') {
      navigate(from, { replace: true });
    }
  };
  handleRedirect();
  }, [route, navigate, from]);
  let [email, setEmail] = useState("");
  let [password, setPassword] = useState("");
  let handleSubmit = async function (event) {
    event.preventDefault();
    try {
      let response = await Auth.signIn(email, password);
      console.log("auth response", response);
      if (response.signInUserSession.accessToken) {
        window.location.href = "/";
        console.log("starting the redirect navigation");
      }
    } catch (e) {
      console.error(e);
    }
    console.log("Finished the submit event");
  };
  
  return (
    <>
    <body className="body">
  <div className="spark-section spark-primary-color-background wf-section">
    <div className="spark-container signin w-container">
      <h1>Realtor Copilot</h1>
      <div className="text-block-6"><span>Set your parameters and press craft.</span>
      <br></br>
      <span>Your Copilot will take it from there.</span>
      </div>
      <div className="spark-centered-form spark-simple-shadow-small signin w-form">
        <div className="spark-form-heading">User Login</div>
        <form id="wf-form-Login-Form" onSubmit={handleSubmit} name="wf-form-Login-Form" data-name="Login-Form" method="get">
          <label htmlFor="Login-Form-Email">Email Address</label>
          <input type="email" onChange={(e) => setEmail(e.target.value)} value={email} className="spark-input w-input" autoComplete="on" maxLength="256" name="Login-Form-Email" data-name="Login-Form-Email" placeholder="Enter Your Email Address" id="Login-Form-Email" required="" />
            <label htmlFor="Login-Form-Password">Password</label>
            <input type="password" onChange={(e) => setPassword(e.target.value)} value={password} className="spark-input w-input" maxLength="256" name="Login-Form-Password" data-name="Login-Form-Password" placeholder="" id="Login-Form-Password" required="" />
              <input type="submit" id="Login-Submit" value="Submit" data-wait="Please wait..." className="spark-button spark-full-width w-button" />
          <div className="spark-form-sub-text login">Don't have an account? <a href="/signup" className="spark-text-link spark-gray">Sign Up</a>
          </div>
        </form>
        <div className="w-form-done">
          <div>Thank you! Your submission has been received!</div>
        </div>
        <div className="w-form-fail">
          <div>Oops! Something went wrong while submitting the form.</div>
        </div>
      </div>
      <a href="forgot-password.html" className="spark-centered-text-link spark-white-text-link">Forgot your password?</a>
    </div>
  </div>
  </body>
  </>
  );
}