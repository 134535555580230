import './App.css';
import './css/webflow.css';
import './css/normalize.css';
import './css/realtor-friend.webflow.css';
import './webflow-override.css';
import { Amplify, Hub } from 'aws-amplify';
import { Authenticator, withAuthenticator, useAuthenticator } from '@aws-amplify/ui-react';
import React, { useState, useEffect, useRef, useNavigate } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from "./home";
import Feedback from "./feedback";
import { Login } from './components/LogIn';
import { RequireAuth } from './RequireAuth';
import SignUp from "./components/signup";

import awsExports from './aws-exports';
Amplify.configure(awsExports);

function MyRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <RequireAuth>
              <Home />
            </RequireAuth>
          }
        />
        <Route
          path="/feedback"
          element={
            <RequireAuth>
              <Feedback />
            </RequireAuth>
          }
        />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
      </Routes>
    </BrowserRouter>
  );
}

export default function App({Component, pageProps}) {
//  const [currentUser, setCurrentUser] = useState();
//
//  const [authStatus, setAuthStatus] = useState("");
//  const listener = (data) => {
//    setAuthStatus(data.payload.event);
//  };
//  console.log(authStatus);
//  Hub.listen("auth", listener);
//
//  useEffect(() => {
//    Hub.listen("auth", (event) => {
//      console.log("auth event", event);
//      setCurrentUser(event.payload.data);
//    });
//  });
  //const [loggedIn, setLoggedIn] = useState(false);
  //const assessLoggedInState = () => {
  //  Auth.currentAuthenticatedUser()
  //    .then(sess => {
  //      console.log('logged in');
  //      setLoggedIn(true);
  //    })
  //    .catch(() => {
  //      console.log('not logged in');
  //      setLoggedIn(false);
  //    })
  //};
  //useEffect(() => {
  //  assessLoggedInState();
  //}, []);

  //const handleSignOut = async () => {
  //  try {
  //    await Auth.signOut();
  //    setLoggedIn(false);
  //  } catch (error) {
  //    console.log('error signing out: ', error);
  //  }
  //};

  //const [authState, setAuthState] = useState();
  //const [user, setUser] = useState();


  return ( 
    
//    <div id="App">
//      {authStatus === "signIn" ? <Home /> : <Login />}
//    </div>
  <Authenticator.Provider>
    <MyRoutes />
  </Authenticator.Provider>

  );
} 

//export default App;
